import React, {Component} from 'react';
import {translate} from 'react-i18nify';
import {Link} from 'react-router-dom';
import {Divider, Icon, List, ListItem, ListItemIcon, ListItemText} from '@mui/material';

import './App.css';

import headerLogo from './assets/img/padelator_header.png';
import logo from './assets/img/logo.gif';
import sponsor from './assets/img/testea_padel.jpg';

class Menu extends Component {

    render() {
        return (
            <div className='list'>
                <List className='menu-list'>
                    <div className={'menu-title'}>
                        <img src={headerLogo} alt={'Logo'}/>
                    </div>
                    <Divider/>
                    <Link to={'/'}>
                        <ListItem button>
                            <ListItemIcon>
                                <Icon>search</Icon>
                            </ListItemIcon>
                            <ListItemText primary={translate('menu.search')}/>
                        </ListItem>
                    </Link>
                    <Divider/>
                    <Link to={'/clubs'}>
                        <ListItem button>
                            <ListItemIcon>
                                <Icon>list</Icon>
                            </ListItemIcon>
                            <ListItemText primary={translate('menu.clubs')}/>
                        </ListItem>
                    </Link>
                    <Divider/>
                    <Link to={'/faq'}>
                        <ListItem button>
                            <ListItemIcon>
                                <Icon>question_answer</Icon>
                            </ListItemIcon>
                            <ListItemText primary={translate('menu.faq')}/>
                        </ListItem>
                    </Link>
                    <Divider/>
                    <Link to={'/about'}>
                        <ListItem button>
                            <ListItemIcon>
                                <Icon>info</Icon>
                            </ListItemIcon>
                            <ListItemText primary={translate('menu.about')}/>
                        </ListItem>
                    </Link>
                    <Divider/>
                    <div className={'menu-logo'}>
                        <img src={logo} alt={'Logo'}/>
                    </div>
                    <Divider/>
                    <div className={'menu-sponsor'}>
                        <ListItemText primary={translate('menu.sponsoredby')}/>
                        <div className={'menu-sponsor-image'}>
                            <a target={'_blank'} href={'http://www.testeapadel.com'}>
                                <img src={sponsor} alt={'Testea Padel'}/>
                            </a>
                        </div>
                    </div>
                </List>
            </div>
        );
    }

}

export default Menu;
