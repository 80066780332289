import React, {useEffect, useState} from 'react';
import GamesFilter from './GamesFilterForm';
import moment from 'moment';
import BlockUi from 'react-block-ui';
import storageService, {CLUBS_FILTER, MAX_LEVEL_FILTER, MIN_LEVEL_FILTER, PROVINCE_FILTER} from '../../StorageService';
import {Link} from 'react-router-dom';
import {LabelValuePair} from '../../index';
import {translate} from 'react-i18nify';
import {Button, Typography} from '@mui/material';

import 'react-block-ui/style.css';
import './GameSearchView.css';

export interface GameSearchFilter {
    minLevel: number;
    maxLevel: number;
    province: string;
    selectedFreeSlots: number;
    clubs?: LabelValuePair[];
    dateFrom?: number;
    dateTo?: number;
    selectedClubs?: LabelValuePair[];
}

export const GameSearchView = () => {
    const [dateFrom, setDateFrom] = useState<number>(moment().valueOf());
    const [dateTo, setDateTo] = useState<number>(moment().endOf('day').valueOf());
    const [minLevel, setMinLevel] = useState<number>(parseFloat(storageService.getItem(MIN_LEVEL_FILTER) || 1));
    const [maxLevel, setMaxLevel] = useState<number>(parseFloat(storageService.getItem(MAX_LEVEL_FILTER) || 7));
    const [selectedFreeSlots, setSelectedFreeSlots] = useState<number>(-1);
    const [clubs, setClubs] = useState<LabelValuePair[]>([]);
    const [selectedClubs, setSelectedClubs] = useState<LabelValuePair[]>(JSON.parse(storageService.getItem(CLUBS_FILTER)) || []);
    const [province, setProvince] = useState<string>(storageService.getItem(PROVINCE_FILTER) || 'Valencia');

    const [blocking, setBlocking] = useState<boolean>(false);
    const [gamesFilterUrl, setGamesFilterUrl] = useState<string>('/games');
    const [filter, setFilter] = useState<GameSearchFilter>({
        minLevel,
        maxLevel,
        province: province,
        selectedFreeSlots,
        clubs,
        dateFrom,
        dateTo,
        selectedClubs
    });

    useEffect(() => {
        // const filterFromStorage = getFilterFromStorage();
        // setFilter(filterFromStorage);
        //eslint-disable-next-line no-console
        // console.table(filterFromStorage);
        _buildGamesFetchUrl();
    }, []);

    const _handleFilterChange = (filterChange: any) => {
        const updatedFilter = Object.assign({...filter}, filterChange);
        // eslint-disable-next-line no-console
        console.debug('Filter changed: ', filterChange);
        // eslint-disable-next-line no-console
        console.table(updatedFilter);
        setFilter(updatedFilter);
    };

    useEffect(() => {
        _buildGamesFetchUrl();
    }, [filter]);

    const _buildGamesFetchUrl = () => {
        const params = [];
        let url = '/games?';
        if (filter.minLevel && filter.minLevel >= 1) {
            params.push(`minLevel=${filter.minLevel}`);
        }
        if (filter.maxLevel && filter.maxLevel <= 7) {
            params.push(`maxLevel=${filter.maxLevel}`);
        }
        if (filter.dateFrom != null) {
            params.push(`dateFrom=${moment(filter.dateFrom).valueOf()}`);
        }
        if (filter.dateTo != null) {
            params.push(`dateTo=${moment(filter.dateTo).valueOf()}`);
        }
        if (filter.selectedClubs && filter.selectedClubs.length > 0) {
            params.push(`clubs=${filter.selectedClubs.map((club: LabelValuePair) => club.value).join(',')}`);
        }
        params.push(`province=${filter.province}`);
        if (filter.selectedFreeSlots && filter.selectedFreeSlots !== -1) {
            params.push(`freeSlots=${filter.selectedFreeSlots}`);
        }
        if (params.length > 0) {
            url = url + params.join('&');
        }
        // eslint-disable-next-line no-console
        console.info(`Games request URL: ${url}`);
        setGamesFilterUrl(url);
    };

    return (
        <div className="root-container grid-container">
            <div className="grid-item">
                <Typography color="primary">
                    {translate('gameSearchView.title')}
                </Typography>
            </div>
            <div className="grid-item">
                <BlockUi tag="div" blocking={blocking}>
                    <GamesFilter filter={filter}
                                 onFilterChange={(filterChange: any) => _handleFilterChange(filterChange)}/>
                </BlockUi>
            </div>
            <div className="grid-item">
                <div className={'search-button'}>
                    <Link to={gamesFilterUrl}>
                        <Button variant="contained" color="primary">
                            {translate('gameSearchView.action')}
                        </Button>
                    </Link>
                </div>
            </div>
            <div>

            </div>
        </div>
    );
};
