import React, {useEffect, useState} from 'react';
import moment from 'moment';
import storageService, {
    CLUBS_FILTER,
    MAX_LEVEL_FILTER,
    MIN_LEVEL_FILTER,
    PROVINCE_FILTER,
    SELECTED_FREE_SLOTS
} from '../../StorageService';
import {ClubService} from '../../clubs/ClubService';
import Notifier, {showMessage} from '../../util/Notifier';
import DatePicker, {registerLocale} from 'react-datepicker';
import {LabelValuePair} from '../../index';
import {GameSearchFilter} from './GameSearchView';
import Select from 'react-select';
import {Button} from '@mui/material';
import {translate} from 'react-i18nify';

import './GamesFilterForm.css';
import 'react-datepicker/dist/react-datepicker.css';
import {userLocale} from '../../util/LanguageUtils';
import es from 'date-fns/locale/es';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-US';
import ca from 'date-fns/locale/ca';
registerLocale('es', es);
registerLocale('en', en);
registerLocale('de', de);
registerLocale('ca', ca);

interface GamesFilterFormProps {
    filter: GameSearchFilter;
    onFilterChange(changedValue: object): void;
}

const GamesFilterForm = (props: GamesFilterFormProps) => {
    const [minLevelValid, setMinLevelValid] = useState(true);
    const [maxLevelValid, setMaxLevelValid] = useState(true);
    const [clubs, setClubs] = useState<LabelValuePair[]>([]);
    const [showCalendar, setShowCalendar] = useState(false);
    const toClose = translate('gamesFilter.toClose');
    const doesNotMatter = translate('gamesFilter.doesNotMatter');
    const freeCourt = translate('gamesFilter.freeCourt');

    const levels: number[] = [1, 1.5, 2, 2.25, 2.5, 2.75, 3, 3.25, 3.5, 3.75, 4, 4.25, 4.5, 4.75, 5, 5.5, 6, 7];
    const freeSlots: LabelValuePair[] = [
        {label: doesNotMatter, value: -1},
        {label: toClose, value: 1},
        {label: '2', value: 2},
        {label: '3', value: 3},
        {label: freeCourt, value: 4}
    ];

    useEffect(() => {
        ClubService.findAll().then(clubItems => {
                const clubs = clubItems.map((club: any) => {
                    return {label: club.name, value: club.id};
                });
                setClubs(clubs);
            },
            error => showMessage(translate('gamesView.errors.serverNotAvailable'))
        );
    }, []);


    const classes: any = props;

    const levelOptions = levels.map((level: number) => {
        return {value: level, label: `${level}`};
    });

    const resolveSelectedFreeSlotsLabel = (value: number): string => {
        let itemWithValue = freeSlots.find(item => item.value === value);
        return itemWithValue ? itemWithValue.label : '';
    };

    const _filterOutDates = (date: Date) => {
        return moment().startOf('day').isBefore(date);
    };

    const _handleDateFromChange = (date: Date) => {
        let asDate = moment(date, 'DD/MM/YYYY').toDate();
        props.onFilterChange({dateFrom: asDate});
    };

    const _handleDateRangeChange = (date: Date) => {
        const dateFrom = moment(date, 'DD/MM/YYYY').startOf('day').toDate();
        const dateTo = moment(date, 'DD/MM/YYYY').endOf('day').toDate();
        props.onFilterChange({dateFrom: dateFrom, dateTo: dateTo});
    };

    const _toggleCalendar = (show: boolean) => {
        setShowCalendar(show);
    };

    useEffect(() => {
        if (showCalendar) {
            _handleDateFromChange(moment(props.filter.dateFrom).toDate());
            _handleDateRangeChange(moment(props.filter.dateFrom).toDate());
            showMessage(translate('app.shared.note'));
        }
    }, [showCalendar]);

    const _handleMinLevelChange = (minLevel: LabelValuePair) => {
        _validateLevel();
        props.onFilterChange({minLevel: minLevel.value});
        storageService.setItem(MIN_LEVEL_FILTER, minLevel.value);
    };

    const _handleMaxLevelChange = (maxLevel: LabelValuePair) => {
        _validateLevel();
        props.onFilterChange({maxLevel: maxLevel.value});
        storageService.setItem(MAX_LEVEL_FILTER, maxLevel.value);
    };

    const _handleProvinceChange = (province: string) => {
        props.onFilterChange({province: province});
        storageService.setItem(PROVINCE_FILTER, province);
        showMessage(translate('gamesFilter.provinceChanged'));
    };

    const _handleFreeSlotsChange = (selectedFreeSlots: number) => {
        props.onFilterChange({selectedFreeSlots: selectedFreeSlots});
        storageService.setItem(SELECTED_FREE_SLOTS, selectedFreeSlots);
    };

    const _handleClubsChange = (selection: any[]) => {
        props.onFilterChange({selectedClubs: selection});
        storageService.setItem(CLUBS_FILTER, JSON.stringify(selection));
    };

    const _validateLevel = () => {
        const levelsValid = props.filter.minLevel
            && props.filter.maxLevel
            && (props.filter.maxLevel >= props.filter.minLevel);
        setMinLevelValid(!!levelsValid);
        setMaxLevelValid(!!levelsValid);
    };

    return (
        <div className="game-filter--container">
            {!showCalendar && <div className="input--container-single">
                <label htmlFor="time">{translate('gamesFilter.timeFrom')}</label>
                <div className={'datepicker-container'}>
                    <DatePicker selected={moment(props.filter.dateFrom).toDate()}
                                onChange={(event: Date) => _handleDateFromChange(event)}
                                showTimeSelect
                                showTimeSelectOnly={true}
                                dateFormat="HH:mm"
                                timeFormat="HH:mm"
                                withPortal={true}
                                timeCaption={translate('app.shared.time')}
                                minTime={moment().hours(7).minutes(30).toDate()}
                                maxTime={moment().hours(23).minutes(30).toDate()}
                    />
                    <Button color={'primary'} size={'medium'} onClick={() => _toggleCalendar(true)}>
                        {translate('gamesFilter.showCalendar')}
                    </Button>
                </div>
            </div>}
            {showCalendar &&
                <div className={'input-date-range'}>
                    <DatePicker
                        selected={moment(props.filter.dateFrom).toDate()}
                        onChange={_handleDateRangeChange}
                        startDate={moment(props.filter.dateFrom).toDate()}
                        endDate={moment(props.filter.dateTo).toDate()}
                        allowSameDay={true}
                        filterDate={_filterOutDates}
                        autoFocus={true}
                        dateFormat="EEEE dd/MM/yyyy"
                        locale={userLocale()}
                    />
                </div>
            }
            <div className="input--container">
                <div className="form-row">
                    <div className="form-input">
                        <label>{translate('gamesFilter.fromLevel')}</label>
                        <Select
                            defaultInputValue={`${props.filter.minLevel}`}
                            className={`${classes.input} ${!minLevelValid ? classes.hasError : ''}`}
                            onChange={(selected: any) => _handleMinLevelChange(selected)}
                            options={levelOptions}>
                        </Select>
                    </div>
                    <div className="form-input">
                        <label>{translate('gamesFilter.toLevel')} </label>
                        <Select
                            defaultInputValue={`${props.filter.maxLevel}`}
                            className={`${classes.input} ${!maxLevelValid ? classes.hasError : ''}`}
                            onChange={(selected: any) => _handleMaxLevelChange(selected)}
                            options={levelOptions}>
                        </Select>
                    </div>
                </div>
            </div>
            <div className="input--container">
                <label>{translate('gamesFilter.selectClubs')} </label>
                <Select
                    closeMenuOnSelect={false}
                    isMulti={true}
                    value={props.filter.selectedClubs}
                    placeholder={translate('gamesFilter.clubs.placeholder')}
                    onChange={(selected: any) => _handleClubsChange(selected)}
                    options={clubs}>
                </Select>
            </div>
            <div className="input--container">
                <label>{translate('gamesFilter.freeSlots')}:</label>
                <Select
                    defaultValue={{
                        label: `${(resolveSelectedFreeSlotsLabel(props.filter.selectedFreeSlots))}`,
                        value: props.filter.selectedFreeSlots
                    }}
                    className={'' + classes.input + ' ' + classes.stringPicker}
                    onChange={(selected: any) => _handleFreeSlotsChange(selected.value)}
                    options={freeSlots}>
                </Select>
            </div>
            <div className="input--container">
                <label>{translate('gamesFilter.province')}:</label>
                <Select
                    defaultValue={{label: props.filter.province, value: props.filter.province}}
                    className={'' + classes.input + ' ' + classes.stringPicker}
                    onChange={(selected: any) => _handleProvinceChange(selected.value)}
                    options={[{label: 'Valencia', value: 'Valencia'}]}>
                </Select>
            </div>
            <Notifier/>
        </div>
    );
};

export default GamesFilterForm;
