import axios from 'axios';

const axiosConfig = {
    baseURL:process.env.REACT_APP_API_URL
};

const apiClient = axios.create(axiosConfig);

// Http Interceptors (not doing anything at the moment but logging activity)
apiClient.interceptors.request.use(request => {
    return request;
});

apiClient.interceptors.response.use(response => {
    return response;
});

export default apiClient;