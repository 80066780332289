import React from 'react';

import HomeIcon from '@mui/icons-material/Home';
import {PadelClub} from '../Model';
import SportsTennisIcon from '@mui/icons-material/SportsTennis';

import './ClubsList.css';
import {Avatar, List, ListItem, ListItemText} from '@mui/material';

interface ClubsListProps {
    clubs: PadelClub[];
}

interface ClubsListState {
    loading: boolean;
    clubs: PadelClub[];
}

export class ClubsList extends React.Component<ClubsListProps, ClubsListState> {

    state = {
        loading: false,
        clubs: []
    };

    render() {
        const clubs = this._buildClubItems();

        return (
            <List>
                {clubs}
            </List>
        );
    }

    _buildClubItems() {
        const clubs = this.props.clubs.map((club: PadelClub) => {
            return (
                <a key={club.id} href={club.url} className='club-item' rel='noopener noreferrer' target='_blank'>
                    <ListItem>
                        <Avatar>
                            <SportsTennisIcon color={'primary'}/>
                        </Avatar>
                        <ListItemText sx={{paddingLeft: '1em'}} primaryTypographyProps={{color: '#395fb4'}} primary={club.name} secondary={club.province}/>
                    </ListItem>
                </a>
            );
        });
        return clubs;
    }
}
