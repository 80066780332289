import * as React from 'react';
import {Snackbar} from '@mui/material';

let showMessageFn: any;

class Notifier extends React.Component {

    state = {
        open: false,
        message: ''
    };

    componentDidMount() {
        showMessageFn = this.showMessage;
    }

    showMessage = (message: string) => {
        this.setState({
            open: true,
            message: message
        });
    };

    _handleSnackbarClose = () => {
        this.setState({
            open: false,
            message: ''
        });
    };

    render() {
        const message = (
            <span
                id='snackbar-message-id'
                dangerouslySetInnerHTML={{__html: this.state.message}}/>
        );

        return (
            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                open={this.state.open}
                onClose={this._handleSnackbarClose}
                autoHideDuration={5000}
                ContentProps={{
                    'aria-describedby': 'snackbar-message-id'
                }}
                message={message}
            />
        );
    }

}

export function showMessage(message: string) {
    showMessageFn(message);
}

export default Notifier;
