import React from 'react';
import moment from 'moment';
import {translate} from 'react-i18nify';
import {Badge, Card, CardActions, CardContent, List} from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import {ClubOpenGamesViewModel, GamesViewModel, PadelClub, PadelGame, PadelPlayer} from '../../Model';

import './GameList.css';

import noGames from '../../assets/img/no-games.png';
import {SportsTennis} from '@mui/icons-material';

interface GameListProps {
    games: GamesViewModel;
}

const GameList = (props: GameListProps) => {
    const _buildClub = (gamesData: ClubOpenGamesViewModel, index: number) => {
        return (
            <div key={gamesData.club.id} className={'club' + (index % 2 === 0 ? ' club-odd' : ' club-even')}>
                <div className="club-info">
                    <Badge className={'club-name'} color="info" badgeContent={_countClubGames(gamesData)}>
                        {gamesData.club.name}
                    </Badge>
                    <div className={'club-actions'}>
                        <a href={gamesData.club.url + gamesData.club.gamesUrlPath} target={'_blank'}>
                            <button type="button">{translate('app.shared.view')}</button>
                        </a>
                        <a href={'tel:' + gamesData.club.phoneNumber}>
                            <button className="call--button" type="button">{translate('app.shared.call')}</button>
                        </a>
                    </div>
                </div>
                <ul>
                    {_buildGames(gamesData)}
                </ul>
            </div>
        );
    };

    const _countClubGames = (data: ClubOpenGamesViewModel): number => {
        const gamesFromClub = data.openGames.filter((game: PadelGame) => game.club === data.club.id);
        return gamesFromClub.length;
    };

    const _buildGames = (gamesData: ClubOpenGamesViewModel) => {
        const games = gamesData.openGames.map((game: PadelGame) => {
            const gameStartMoment = moment(game.startTime);
            const gameEndMoment = moment(game.endTime);
            let timeFormat = 'HH:mm';

            return (
                <div key={game.id}>
                    <li>
                        <Card raised={true} className="game-frame">
                            <CardContent>
                                <div className="game-data">
                                    <div className={'game-data-header'}>
                                        {gameStartMoment.format(timeFormat)} -- {gameEndMoment.format('HH:mm')}
                                    </div>
                                    <div className={'game-data-secondary'}>
                                        {translate('gamesList.level')}: {game.level}
                                    </div>
                                </div>
                                <div className="players-grid">
                                    {_buildPlayers(game.players, gamesData.club.url)}
                                    {_buildJoinSlots(game.players, gamesData.club, game)}
                                </div>
                                {game.comments &&
                                    <div className={'game-data-comments'}>{game.comments}</div>}
                            </CardContent>
                            <CardActions>
                            </CardActions>
                        </Card>
                    </li>
                </div>

            );
        });
        return (games);
    };

    const _buildPlayers = (players: PadelPlayer[], clubUrl: string) => {
        return players.map((player: PadelPlayer) => (
            <div className={'player-slot'} key={player.id}>
                <div className={'player-data'}>
                    <div className={'player-name'}>
                        {player.nickname}
                    </div>
                    <div className={'player-level'}>
                        <b>{player.level}</b>
                    </div>
                    <div className={'player-position'}>
                        {player.position ? player.position : 'Posición: N/D'}
                    </div>
                </div>
                <div className={'player-image'}>
                    {player.pictureUrl != null && <img alt={player.nickname}
                         src={`${clubUrl}/${player.pictureUrl}`}
                         height={20}/>}
                    {!player.pictureUrl && <SportsTennis color={'primary'}/>}
                </div>
            </div>
        ));
    };

    const _buildJoinSlots = (players: PadelPlayer[], club: PadelClub, game: PadelGame) => {
        const joinSlots = [];
        const currentTime = new Date().getHours();
        const dayOfGameStart = moment(game.startTime).get('dayOfYear');
        let weekDayAsString = 'de hoy';
        const currentDayInYear = moment().get('dayOfYear');
        const differenceInDays = dayOfGameStart - currentDayInYear;
        if (differenceInDays === 1) {
            weekDayAsString = 'de mañana';
        }
        if (differenceInDays > 1) {
            weekDayAsString = `del ${moment(game.startTime).format('dddd')}`;
        }
        const courtMessage = (game.court && game.court) !== '' ? `La de la pista ${game.court}. Gracias` : '';
        const greetings = currentTime < 12 ? 'Buenos días' : currentTime < 20 ? 'Buenas tardes' : 'Buenas noches';
        const message = `Hola, ${greetings}. Me gustaría apuntarme a la partida de ${game.level} ${weekDayAsString} 
                        a las ${moment(game.startTime).format('H:mm')}. ${courtMessage}`;
        for (let i = 0; i < (4 - players.length); i++) {
            const whatsAppEnabled = club.phoneNumber.startsWith('6');
            let joinUrl = whatsAppEnabled ?
                `https://api.whatsapp.com/send?phone=34${club.phoneNumber}&text=${message}` :
                `tel:${club.phoneNumber}`;
            joinSlots.push(
                <div key={i} className={'player-slot-free'} style={{backgroundColor: '#94ce98'}}>
                    <a target={'_blank'}
                       href={joinUrl}
                       key={club.id + i}>
                        <div className={'player-data'}>
                            <span className={'join-button'}>
                                {translate(whatsAppEnabled ? 'gamesList.join' : 'gamesList.free')}
                                {whatsAppEnabled && <WhatsAppIcon/>}
                                {!whatsAppEnabled && <PhoneIcon/>}
                            </span>

                        </div>
                    </a>
                </div>
            );
        }
        return joinSlots;
    };

    if (props.games.gamesCount > 0) {
        const gameRenderItems = props.games.clubs.map((gamesItem: ClubOpenGamesViewModel, index: number) => {
            return (
                _buildClub(gamesItem, index)
            );
        });
        return (
            <div id="container">
                <List>
                    {gameRenderItems}
                </List>
            </div>
        );
    } else {
        if (typeof props.games.gamesCount === 'undefined') {
            return null;
        }
        return (
            <div className="no-games">
                <img width={50} src={noGames} alt={translate('gamesView.nogames')}/>
            </div>
        );
    }
};

export default GameList;
