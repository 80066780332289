import React, {PureComponent} from 'react';
import {Typography} from '@mui/material';
import {translate} from 'react-i18nify';

import './FaqView.css';

export class FaqView extends PureComponent {

    render() {
        return (
            <div className={'about-view--container'}>
                <Typography variant={'h4'} paragraph={true}>
                    {translate('faqView.title')}
                </Typography>
                <Typography variant={'h6'} paragraph={false}>
                    ¿Para qué sirve Padelator?
                </Typography>
                <Typography variant={'body2'} paragraph={true}>
                    Padelator es una plataforma desarrollada con el objetivo de impulsar el Padel. Facilita que los
                    jugadores encuentren partidas adecuadas a su nivel y horario, y ayuda a los clubes a cerrar partidas
                    abiertas en el día.
                </Typography>
                <Typography variant={'h6'} paragraph={false}>
                    ¿Cuesta algo Padelator?
                </Typography>
                <Typography variant={'body2'} paragraph={true}>
                    Padelator es completamente gratuito, sin límite de uso.
                </Typography>
                <Typography variant={'h6'} paragraph={false}>
                    ¿Como sé el nivel que tengo?
                </Typography>
                <Typography variant={'body2'} paragraph={true}>
                    El nivel de padel se suele medir de 1 a 7, donde 7 se supondría el nivel del mejor jugador del mundo
                    y 1 una persona que coge una pala de padel por primera vez, sin haber pasado por ningún otro deporte
                    de raqueta.
                    Lo mejor es que hables con tu club habitual, juegues una partida, y te orienten sobre tu nivel.
                    Hay clubs en los que no se puntua de esta manera, sino en categorísa de 4ª a 1ª. Entendemos que, de
                    forma aproximada, la correspondencia sería la siguiente:
                    <ul>
                        <li>1ª: Nivel superior a 4.75</li>
                        <li>2ª: 4.5 - 4.75</li>
                        <li>3ª: 4 - 4.25</li>
                        <li>4ª: 3.5 - 4</li>
                    </ul>
                    Por debajo de estos niveles lo mejor es mantenerse en el primer sistema de puntuación ya mencionado.
                </Typography>
                <Typography variant={'h6'} paragraph={false}>
                    ¿Y si mi club no está en la lista?
                </Typography>
                <Typography variant={'body2'} paragraph={true}>
                    Si tu club no está en la lista, pero tiene web en la que se pueden ver las partidas abiertas, ponte
                    en contacto con nosotros e intentaremos incluirlo lo antes posible.
                </Typography>
                <Typography variant={'h6'} paragraph={false}>
                    He abierto una partida en mi club, pero no aparece en Padelator. ¿Por que?
                </Typography>
                <Typography variant={'body2'} paragraph={true}>
                    Padelator actualiza la información cada pocos minutos. Si la acabas de abrir, podría ser que pasaran
                    algunos minutos hasta que salga en la lista.
                </Typography>
            </div>
        );
    }

}
