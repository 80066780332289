import {createTheme} from '@mui/material';

export const Theme = createTheme({
    palette: {
        primary: {
            light: '#5a8be8',
            main: '#3b60b5',
            dark: '#0d4684',
            contrastText: '#fff'
        },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#000'
        }
    }
});
