import React, {Component, Fragment} from 'react';
import {GameSearchView} from './games/search/GameSearchView';
import {Redirect, Route, Switch} from 'react-router-dom';
import {ClubsView} from './clubs/ClubsView';
import {GamesView} from './games/list/GamesView';

import './App.css';
import {Theme} from './Theme';
import {AboutView} from './about/AboutView';
import headerLogo from './assets/img/padelator_header.png';
import {FaqView} from './faq/FaqView';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from './Menu';
import {AppBar, CssBaseline, Drawer, IconButton, ThemeProvider, Toolbar, Typography} from '@mui/material';

const appVersion = '1.3.5';

interface AppState {
    menu: MenuState;
}

interface MenuState {
    open: boolean;
}

class App extends Component<any, AppState> {

    state: AppState = {
        menu: {
            open: false
        }
    };

    render() {
        return (
            <Fragment>
                <CssBaseline/>
                <ThemeProvider theme={Theme}>
                    <AppBar position='static'>
                        <Toolbar>
                            <IconButton color='inherit' aria-label='Menu' onClick={this.toggleDrawer}>
                                <MenuIcon/>
                            </IconButton>
                            <img src={headerLogo} onClick={this.toggleDrawer} alt={'Header logo'}/>
                            <div className={'header-separator'}></div>
                            <Typography variant='body1' color='inherit'>
                                v{appVersion}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Drawer anchor='left' open={this.state.menu.open} onClose={this.toggleDrawer}>
                        <div
                            tabIndex={0}
                            role='button'
                            onClick={this.toggleDrawer}
                            onKeyDown={this.toggleDrawer}>
                            <Menu/>
                        </div>
                    </Drawer>
                    <Switch>
                        <Route path='/' exact component={GameSearchView}/>
                        <Route path='/clubs' exact component={ClubsView}/>
                        <Route path='/games' component={GamesView}/>
                        <Route path='/about' component={AboutView}/>
                        <Route path='/faq' component={FaqView}/>
                        <Redirect from='*' to='/'/>
                    </Switch>
                </ThemeProvider>
            </Fragment>
        );
    }

    toggleDrawer = () => {
        this.setState({menu: {open: !this.state.menu.open}});
    };

}

export default App;
