import apiClient from '../Api';

export class ClubService {

    static findAll = () => {
        return apiClient.get('/clubs/padel')
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.message));
    };

    static findProvinces = () => {
        return apiClient.get('/clubs/padel/provinces')
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.message));
    };

    static findByProvince = (province: string) => {
        return apiClient.get(`/clubs/padel?province=${province}`)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.message));
    };

}
