export const MIN_LEVEL_FILTER = 'minLevel';
export const MAX_LEVEL_FILTER = 'maxLevel';
export const PROVINCE_FILTER = 'province';
export const SELECTED_FREE_SLOTS = 'freeSlots';
export const CLUBS_FILTER = 'clubs';

export default class StorageService {

    static getItem(itemName: string): any {
        return window.localStorage.getItem(itemName);
    }

    static setItem(itemName: string, value: any): void {
        window.localStorage.setItem(itemName, value);
    }

}
