import React, {PureComponent} from 'react';
import {translate} from 'react-i18nify';
import {ClubService} from './ClubService';
import {PadelClub} from '../Model';
import {ClubsList} from './ClubsList';
import {Button, Card, CardActions, CardContent, Typography} from '@mui/material';

import './ClubsView.css';

export class ClubsView extends PureComponent {

    state = {
        clubs: []
    };

    componentDidMount() {
        ClubService.findAll().then((clubs: PadelClub[]) => {
            this.setState({clubs});
        });
    }

    render() {
        return (
            <div className='clubs-view--container'>
                <Card>
                    <CardContent className='add-club--action'>
                        <Typography>
                            {translate('clubsListView.clubMissing')}
                        </Typography>
                    </CardContent>
                    <CardActions className='add-club--action'>
                        <Button variant='contained' color='primary'>
                            <a href={'mailto:info@padelator.net?subject=Solicitud club Padelator'}>{translate('clubsListView.contactUs')}</a>
                        </Button>
                    </CardActions>
                </Card>

                <ClubsList clubs={this.state.clubs}/>
            </div>
        );
    }

}
