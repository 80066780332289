import React, {useEffect} from 'react';
import {AxiosResponse} from 'axios';
import BlockUi from 'react-block-ui';

import GameList from './GameList';
import {GamesViewModel} from '../../Model';
import {showMessage} from '../../util/Notifier';
import apiClient from '../../Api';

import {translate} from 'react-i18nify';
import 'react-block-ui/style.css';
import './GamesView.css';

import sponsor from '../../assets/img/testea_padel_md.jpg';
import {Button, Typography} from '@mui/material';
import moment from 'moment';

export const GamesView = (props: any) => {
    const [blocking, setBlocking] = React.useState(false);
    const [clubGamesModel, setClubGamesModel] = React.useState<GamesViewModel>({
        clubs: [],
        gamesCount: 0,
        searchTimeInSeconds: 0
    });
    const [weekDay, setWeekDay] = React.useState<string>('');

    const _fetchGames = () => {
        return apiClient.get(`${process.env.REACT_APP_API_URL}/games/padel${props.location.search}`);
    };

    const _refresh = () => {
        setBlocking(true);
        window.location.reload();
    };

    const _back = () => {
        // @ts-ignore
        props.history.goBack();
    };

    useEffect(() => {
        setBlocking(true);
        _fetchGames().then(
            (response: AxiosResponse<GamesViewModel>) => {
                setClubGamesModel(response.data);
                setBlocking(false);
                if (response.data.gamesCount > 0) {
                    setWeekDay(moment(response.data.clubs[0].openGames[0].startTime).format('dddd'));
                }
            },
            (error: any) => {
                setBlocking(false);
                showMessage(translate('gamesView.errors.serverNotAvailable'));
            });
    }, []);

    return (
        <div className="games-view-container games-view-grid-container">
            {clubGamesModel.gamesCount > 0 && <div className="grid-item games-view-title">
                <Typography color="primary">
                    {clubGamesModel.gamesCount > 0
                        && (clubGamesModel.gamesCount + translate('gamesView.title', {weekDay: weekDay}))}
                </Typography>
            </div>}
            <div className="grid-item">
                <BlockUi tag="div" blocking={blocking}>
                    <GameList games={clubGamesModel}/>
                </BlockUi>
            </div>
            <div className="grid-item">
                {clubGamesModel.gamesCount > 0 &&
                    <Button variant="contained" color="primary" onClick={_refresh}>
                        {translate('app.shared.refresh')}
                    </Button>}
                {clubGamesModel.gamesCount === 0 &&
                    <Button variant="contained" color="primary" onClick={_back}>
                        {translate('app.shared.back')}
                    </Button>}
            </div>
            <div className={'menu-sponsor'}>
                <p>{translate('menu.sponsoredby')}</p>
                <a target={'_blank'} href={'http://www.testeapadel.com'}>
                    <img src={sponsor} alt={'Testea Padel'}/>
                </a>
            </div>
        </div>
    );
};

