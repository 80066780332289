import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {HashRouter} from 'react-router-dom';
import * as moment from 'moment';
import messages from './i18n';

import './index.css';
import {userLocale} from './util/LanguageUtils';
const { setTranslations, setLocale } = require('react-i18nify');

export interface LabelValuePair {
    label: string;
    value: any;
}

setTranslations(messages);
setLocale(userLocale());

// @ts-ignore
const app = (
    <HashRouter basename='/'>
        <App/>
    </HashRouter>
);

moment.locale(userLocale());

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
