import { Typography } from '@mui/material';
import React, {PureComponent} from 'react';

import './AboutView.css';
import {translate} from 'react-i18nify';

export class AboutView extends PureComponent {

    render() {
        return (
            <div className='about-view--container'>
                <Typography paragraph={true}>
                    {translate('aboutView.title')}
                </Typography>
                <Typography variant={'body1'} paragraph={true}>
                    Padelator es una iniciativa con el objectivo de impulsar el Padel en la Comunidad
                    Valenciana, bajo la idea de poder buscar partidas a las que apuntarse de forma rápida y sencilla,
                        de manera que los clubes llenen más pistas y los usuarios jueguen más y mejores partidas.
                </Typography>
                <Typography variant={'body1'}>
                    Tiene un motor de búsqueda que incluye muchos clubes de la Comunidad Valenciana.
                </Typography>
                <Typography variant={'body1'}>
                    Si tu club no se encuentra en la lista, ponte en contacto con nosotros y veremos la posibilidad de
                    incluirlo.
                </Typography>
                <Typography variant={'body1'}>
                    El único requerimiento es que el club tenga web y en ella se puedan ver, de forma pública, las
                    partidas abiertas.
                </Typography>
                <Typography variant={'body1'} paragraph={true}>
                    Si tienes alguna duda o sugerencia para mejorar la aplicación, no dejes de ponerte en contacto con nosotros.
                </Typography>
                <Typography variant={'body1'} paragraph={true}>
                    El uso de Padelator es completamente gratuito, sin necesidad de instalar nada, ni registrarse.
                </Typography>
                <Typography variant={'body1'}>
                    Esperamos que la disfrutes.
                </Typography>
                <Typography variant={'body1'}>
                    - El equipo de Padelator -</Typography>
            </div>
        );
    }

}
