const messages: any = {
    es: {
        app: {
            title: 'Padelator',
            shared: {
                search: 'Buscar',
                refresh: 'Actualizar',
                back: 'Volver',
                call: 'Llamar',
                view: 'Ver club',
                loading: 'Cargando...',
                time: 'Hora',
                note: 'IMPORTANTE: No todos los clubes tiene disponibles partidas de otros días!!',
                today: 'Hoy'
            }
        },
        menu: {
            search: 'Buscador',
            clubs: 'Clubes',
            about: 'Acerca de...',
            faq: 'F.A.Q',
            sponsoredby: 'Esponsorizado por'
        },
        gameSearchView: {
            title: 'Buscador de partidas',
            action: 'Buscar partidas'
        },
        gamesView: {
            title: ' partidas encontradas para el %{weekDay}',
            nogames: 'No hemos encontrado partidas en base al criterio seleccionado',
            errors: {
                serverNotAvailable: 'El servidor parece no estar disponible. Por favor, inténtalo más tarde'
            }
        },
        gamesList: {
            court: 'Pista',
            starts: 'Empieza',
            ends: 'Termina',
            level: 'Nivel',
            freeSlots: 'Faltan',
            comments: 'Comentarios',
            join: 'Apúntame',
            free: 'Libre'
        },
        clubsListView: {
            clubMissing: '¿Falta tu club?',
            contactUs: 'Contáctanos'
        },
        gamesFilter: {
            today: 'Para hoy',
            timeFrom: 'Hoy a partir de las',
            dateFrom: 'Desde el',
            dateTo: 'al',
            showCalendar: 'Próximos días',
            hideCalendar: '<= Volver a hoy',
            fromLevel: 'Nivel desde',
            toLevel: 'hasta',
            selectClubs: 'Clubs',
            province: 'Provincia',
            clubs: {
                placeholder: 'Todos los clubs'
            },
            freeSlots: 'Plazas libres',
            toClose: 'Para cerrar (1)',
            doesNotMatter: 'da igual',
            freeCourt: 'Pista libre',
            provinceChanged: 'Se han ajustado los clubes para la provincia'
        },
        aboutView: {
            title: 'Acerca de Padelator'
        },
        faqView: {
            title: 'Preguntas frecuentes'
        }
    },
    val: {
        app: {
            title: 'Padelator',
            shared: {
                search: 'Buscar',
                refresh: 'Actualitzar',
                back: 'Tornar',
                call: 'Cridar',
                view: 'Vore club',
                loading: 'Carregant...',
                time: 'Hora',
                note: 'IMPORTANT: No tots els clubs tener partides d\'altres dies!!',
                today: 'Hui'
            }
        },
        menu: {
            search: 'Buscador',
            clubs: 'Clubs',
            about: 'Padelator',
            faq: 'F.A.Q',
            sponsoredby: 'Esponsoritzat per'
        },
        gameSearchView: {
            title: 'Buscador de partides',
            action: 'Buscar partides'
        },
        gamesView: {
            title: ' partides trobades per al %{weekay}',
            nogames: 'No hem trobat partides en base al criteri seleccionat',
            errors: {
                serverNotAvailable: 'El servidor pareix no estar disponible. Per favor intenta ho mes tard'
            }
        },
        gamesList: {
            court: 'Pista',
            starts: 'Comença',
            ends: 'Acaba',
            level: 'Nivell',
            freeSlots: 'Falten',
            comments: 'Comentaris',
            join: 'M\'apunte',
            free: 'Lliure'
        },
        clubsListView: {
            clubMissing: '¿Falta el teu club?',
            contactUs: 'Contactans'
        },
        gamesFilter: {
            today: 'Per avui',
            timeFrom: 'Hui a partir de les',
            dateFrom: 'Desde el',
            dateTo: 'fins el',
            showCalendar: 'Próxims dies',
            hideCalendar: '<= Tornar a hui',
            fromLevel: 'Nivell desde',
            toLevel: 'fins a',
            selectClubs: 'Clubs',
            province: 'Provincia',
            clubs: {
                placeholder: 'Tots els clubs'
            },
            freeSlots: 'Places lliures',
            toClose: 'Per a tancar (1)',
            doesNotMatter: 'es igual',
            freeCourt: 'Pista lliure',
            provinceChanged: 'S\'han ajustat els clubs per a la provincia'
        },
        aboutView: {
            title: 'Padelator'
        },
        faqView: {
            title: 'Preguntes frequents'
        }
    },
    en: {
        app: {
            title: 'Padelator',
            shared: {
                search: 'Search',
                refresh: 'Refresh',
                back: 'Back',
                call: 'Call',
                view: 'Show club',
                loading: 'Loading...',
                time: 'Time',
                note: 'IMPORTANT: Not every club has games from other dates!!',
                today: 'Today'
            }
        },
        menu: {
            search: 'Search games',
            clubs: 'Clubs',
            about: 'About Padelator',
            faq: 'F.A.Q',
            sponsoredby: 'Sponsored by'
        },
        gameSearchView: {
            title: 'Game search',
            action: 'Search games'
        },
        gamesView: {
            title: ' games found for %{weekDay}',
            nogames: 'Based on your criteria, we did not find any games',
            errors: {
                serverNotAvailable: 'Server is not available. Please try again later'
            }
        },
        gamesList: {
            court: 'Court',
            starts: 'Starts',
            ends: 'Ends',
            level: 'Game level',
            freeSlots: 'Free slots',
            comments: 'Comments',
            join: 'Join',
            free: 'Free'
        },
        clubsListView: {
            clubMissing: 'Is your club missing?',
            contactUs: 'Contact us'
        },
        gamesFilter: {
            today: 'Today',
            timeFrom: 'Today from',
            dateFrom: 'From',
            dateTo: 'to',
            showCalendar: 'Next days',
            hideCalendar: '<= Back to Today',
            fromLevel: 'Level from',
            toLevel: 'to',
            selectClubs: 'Clubs',
            province: 'Province',
            clubs: {
                placeholder: 'All clubs'
            },
            freeSlots: 'Free slots',
            toClose: 'To complete (1)',
            doesNotMatter: 'not important',
            freeCourt: 'Free court',
            provinceChanged: 'Clubs for the province have been adjusted'
        },
        aboutView: {
            title: 'About Padelator'
        },
        faqView: {
            title: 'Frequently asked questions'
        }
    },
    de: {
        app: {
            title: 'Padelator',
            shared: {
                search: 'Suchen',
                refresh: 'Aktualisieren',
                back: 'Züruck',
                call: 'Anrufen',
                view: 'Club Zeigen',
                loading: 'Wird geladen...',
                time: 'Zeit',
                note: 'WICHTIG: Nicht alle Clubs, haben spielen von andere Datum!!',
                today: 'Heute'
            }
        },
        menu: {
            search: 'Spiel suchen',
            clubs: 'Vereine',
            about: 'Über Padelator',
            faq: 'F.A.Q',
            sponsoredby: 'Gesponsert durch'
        },
        gameSearchView: {
            title: 'Spiel suchen',
            action: 'Suchen'
        },
        gamesView: {
            title: ' Spiele gefunden am %{weekDay}',
            nogames: 'basierend auf deinen Kriterien haben wir keine Spiele gefunden',
            errors: {
                serverNotAvailable: 'Server würde nicht verfügbar. Versuchen Sie bitte, ein bischen später'
            }
        },
        gamesList: {
            court: 'Platz',
            starts: 'Beginnt',
            ends: 'Endet',
            level: 'Spiellevel',
            freeSlots: 'Freie Plätze',
            comments: 'Kommentare',
            join: 'Bin dabei',
            free: 'Frei'
        },
        clubsListView: {
            clubMissing: 'Fehlt dein Club?',
            contactUs: 'Kontaktieren Sie uns'
        },
        gamesFilter: {
            today: 'Heute',
            timeFrom: 'Heute ab',
            dateFrom: 'Ab',
            dateTo: 'bis',
            showCalendar: 'Nächsten Tage',
            hideCalendar: 'Zurück zu Heute =>',
            fromLevel: 'Stufe von',
            toLevel: 'bis',
            selectClubs: 'Clubs',
            province: 'Provinz',
            clubs: {
                placeholder: 'Alle Vereine'
            },
            freeSlots: 'Frei Plätze',
            toClose: 'Zu Komplet machen (1)',
            doesNotMatter: 'mir egal',
            freeCourt: 'Freies Gericht',
            provinceChanged: 'Die Vereine für der Provinz würden angepast'
        },
        aboutView: {
            title: 'Über Padelator'
        },
        faqView: {
            title: 'Häufig gestellte Fragen'
        }
    }
};

export default messages;
